import React, { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { HttpNoData, getRandomColor } from "../../../Core";
import styles from '../Style/home.module.css';

function Home(props) {

    const [color, setColor] = useState(0);
    const [productsDay, setProductsDay] = useState([]);
    const [products, setProducts] = useState([]);

    const [leadsDay, setLeadsDay] = useState([]);
    const [leads, setLeads] = useState([]);

    const [productsAnalyze, setProductsAnalyze] = useState();

    const [currentPanel, setCurrentPanel] = useState('data');
    const [currentIndexChart, setCurrentIndexChart] = useState(0);

    const [jwt, setJwt] = useState(props.jwt);

    const UpdateData = async() => {

        const responseLeads = await HttpNoData('/api/v0/leads', 'GET', jwt);

        if(!responseLeads.statusSuccessful){
            console.log(responseLeads.error);
            return;
        }

        const responseLeadSends = await HttpNoData('/api/v0/leadSends/analyze/group/month', 'GET', jwt);

        if(!responseLeadSends.statusSuccessful){
            console.log(responseLeadSends.error);
            return;
        }

        const responseLeadGroup = await HttpNoData('/api/v0/leadSends/analyze/group/lead', 'GET', jwt);

        if(!responseLeadGroup.statusSuccessful){
            console.log(responseLeadGroup.error);
            return;
        }

        const responseProducts = await HttpNoData('/api/v0/products', 'GET', jwt);

        if(!responseProducts.statusSuccessful){
            console.log(responseProducts.error);
            return;
        }

        const responseProductSends = await HttpNoData('/api/v0/productsSend/analyze/group/month', 'GET', jwt);

        if(!responseProductSends.statusSuccessful)
        {
            console.log(responseProductSends.error);
            return;
        }

        const responseProductGroup = await HttpNoData('/api/v0/productsSend/analyze/group/product', 'GET', jwt);

        if(!responseProductGroup.statusSuccessful){
            console.log(responseProductGroup.error);
            return;
        }

        const products = responseProducts.data;
        for (let i = 0; i < products.length; i++) {

            const valueTemp = responseProductGroup.data.find(o => o.id == products[i].id);

            products[i] = {
                ...products[i],
                value: valueTemp ? valueTemp.value : 0
            };
        }

        const leads = responseLeads.data;
        for (let i = 0; i < leads.length; i++) {

            const valueTemp = responseLeadGroup.data.find(o => o.id == leads[i].id)

            leads[i] = {
                ...leads[i],
                value: valueTemp ? valueTemp.value : 0
            };
        }

        const resultProductsDay = responseProductSends.data;

        const sumProducts = resultProductsDay.map(o => o.value).reduce((p, e) => p + e);
        const maxProduct = Math.max(...products.map(o => o.value));
        const minProduct = Math.min(...products.map(o => o.value));
        setProductsAnalyze({
            sum: sumProducts,
            average: Math.round(sumProducts / resultProductsDay.length),
            maxOnDay: Math.max(...resultProductsDay.map(o => o.value)),
            minOnDay: Math.min(...resultProductsDay.map(o => o.value)),
            maxOnProduct: products.find(o => o.value === maxProduct),
            minOnProduct: products.find(o => o.value === minProduct)
        });
        
        setProducts(products);
        setProductsDay(resultProductsDay);
        setLeads(leads);
        setLeadsDay(responseLeadSends.data);
    }

    const ContentItem = (props) => {
        const { title, content } = props;

        return <div className={styles.contentItem}>
            <h3 className={styles.contentTitle}>{title}</h3>
            <h5 className={styles.contentItemBody}>
                {content}
            </h5>
        </div>
    }

    useEffect(() => {
        UpdateData();
    }, []);

    const Charts = [
        {
            title: 'Продукция',
            chart: <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={"100%"}
                    height={"100%"}
                    data={products}>
                    <XAxis dataKey="name" />
                    <Tooltip />
                    <Bar stackId={1} name='Кол-во' dataKey="value" fill={getRandomColor()} />
                </BarChart>
            </ResponsiveContainer>
        },
        {
        title: 'Продукция по дням',
        chart: <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={"100%"}
                height={"100%"}
                data={productsDay}>
                <XAxis dataKey="day" />
                <Tooltip />
                <Bar name='Кол-во' dataKey={'value'} fill={getRandomColor()} />
            </BarChart>
        </ResponsiveContainer>
    },
    {
        title: 'Лиды',
        chart: <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={"100%"}
                height={"100%"}
                data={leads}>
                <XAxis dataKey="name" />
                <Tooltip />
                <Bar name='Сумма' stackId={1} dataKey="value" fill={getRandomColor()} />
            </BarChart>
        </ResponsiveContainer>
    },
    {
        title: 'Лиды в соотношение',
        chart: <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={"100%"}
                height={"100%"}
                stackOffset="expand"
                data={leadsDay}>
                <XAxis dataKey="day" />
                <Tooltip />
                <Bar name='Общее отправленных заказов' stackId={1} dataKey="valueSend" fill={getRandomColor()} />
                <Bar name='Общее кол-во заявок' stackId={1} dataKey="valueOrder" fill={getRandomColor()} />
                <Bar name='Объем заявок, тыс. руб.' stackId={1} dataKey="valueObm" fill={getRandomColor()} />
            </BarChart>
        </ResponsiveContainer>
    },
    {
        title: 'Лиды по дням',
        chart: <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={"100%"}
                height={"100%"}
                data={leadsDay}>
                <XAxis dataKey="day" />
                <Tooltip />
                <Bar name='Общее отправленных заказов' stackId={1} dataKey="valueSend" fill={getRandomColor()} />
                <Bar name='Общее кол-во заявок' stackId={1} dataKey="valueOrder" fill={getRandomColor()} />
                <Bar name='Объем заявок, тыс. руб.' stackId={1} dataKey="valueObm" fill={getRandomColor()} />
            </BarChart>
        </ResponsiveContainer>
    }
    
];

    const CurrentChart = <div className={styles.charts}>
        <div className={styles.buttons}>
            {
                Charts.map((o, i) => {
                    return <button key={i} onClick={() => setCurrentIndexChart(i)} className={i === currentIndexChart ? styles.button : styles.buttonDisActive}>{o.title}</button>
                })
            }
        </div>
        <div className={styles.chart}>
            {
                Charts[currentIndexChart].chart
            }
        </div>
    </div>;

    if(!productsAnalyze)
            return null;

    return (
        <div className={styles.main_body}>
            <div className={styles.content}>
                <div className={styles.buttons}>
                    <button className={currentPanel === 'data' ? styles.button : styles.buttonDisActive} onClick={() => setCurrentPanel('data')}>Данные</button>
                    <button className={currentPanel === 'charts' ? styles.button : styles.buttonDisActive} onClick={() => setCurrentPanel('charts')}>Графики</button>
                </div>
                {
                    currentPanel === 'data' ?
                        <React.Fragment>
                            <h2 className={styles.title}>Продукция</h2>
                            <div className={styles.data}>
                                <ContentItem title='Отправленная' content={ productsAnalyze.sum} />
                                <ContentItem title='Среднее кол-во в сутки' content={ productsAnalyze.average }/>
                                <ContentItem title='Наибольшая отправка в сутки' content={ productsAnalyze.maxOnDay }/>
                                
                                <ContentItem title='Наименьшая отправка в сутки' content={productsAnalyze.minOnDay }/>
                                <ContentItem title='Наибольшая отправка продукции' content={
                                    <React.Fragment>
                                    {productsAnalyze.maxOnProduct.name}
                                    <br/>
                                    {productsAnalyze.maxOnProduct.value}
                                    </React.Fragment>
                                    }/>
                                <ContentItem title='Наименьшая отправка продукции' content={
                                    <React.Fragment>
                                    {productsAnalyze.minOnProduct.name}
                                    <br/>
                                    {productsAnalyze.minOnProduct.value}
                                    </React.Fragment>
                                    }/>
                            </div>
                        </React.Fragment>
                        : CurrentChart
                }
            </div>
            <div onClick={() => setColor(color + 1)} className={styles.updateColor}>обновить цвета {color}</div>
        </div>
    );
}

export default Home;