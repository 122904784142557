import React, { useEffect, useState } from "react";
import { MdCheck, MdClear, MdEdit } from "react-icons/md";
import { HttpNoData } from "../../Core";
import IconLoad from '../Main/IconLoad';
import styles from './Profile.module.css';

function Profile(props) {

    const [user, setUser] = useState(props.user);
    const [jwt, setJwt] = useState(props.jwt);
    const [property, setProperty] = useState();

    if (user == null)
        return <IconLoad className={styles.load} />;

    const UpdateUser = async (value, successCallback) => {

        const response = await HttpNoData('/api/v0/account/' + property + '?value=' + value, 'PUT', jwt);

        if (response.statusSuccessful) {

            var upadateJWT = jwt;
            if (successCallback != null || successCallback)
                upadateJWT = successCallback(response);

            setUser(await props.updateUser(upadateJWT));
        } else console.log(response.error);
    }

    function FieldEdit(props) {

        const [propertyValue, setPropertyValue] = useState();
        const { propertyName, value, typeInput, successCallback } = props;

        useEffect(() => setPropertyValue(value), []);

        const isCurrentEdit = property == propertyName;

        return <div className={styles.boxEdititonal}>
            <input type={typeInput == null ? 'text' : typeInput} className={styles.blockInput} disabled={!isCurrentEdit} value={isCurrentEdit ? propertyValue : value} onChange={(e) => setPropertyValue(e.target.value)} />
            {
                isCurrentEdit ?
                    <React.Fragment>
                        <MdCheck className={styles.saveIcon} onClick={() => {
                            UpdateUser(propertyValue, successCallback);
                            setProperty('');
                        }} />
                        <MdClear className={styles.cancelIcon} onClick={() => setProperty(null)} />
                    </React.Fragment>
                    :
                    <MdEdit className={styles.editIcon} onClick={() => {
                        setProperty(propertyName);
                    }} />
            }
        </div>
    }

    function FieldReadOnly(props) {

        const { value } = props;
        return <div className={styles.boxEdititonal}>
            <input className={styles.readOnlyInput} disabled={true} defaultValue={value} />
        </div>
    }

    function ContentElement(props) {

        const { name, content } = props;

        if (content == null)
            return null;

        return <div className={styles.contentElement}>
            <label className={styles.titleContentElement}>{name}</label>
            {content}
        </div>
    }

    function Manager(props) {

        const content = <div className={styles.contentNoShadow}>
            <ContentElement name='Имя' content={<FieldEdit propertyName='manager/name' value={user.manager.name} />} />
            <ContentElement name='Доступ' content={<FieldReadOnly value={user.manager.root} />} />
        </div>;

        return content
    }

    function Analytic(props) {

        const content = <div className={styles.contentNoShadow}>
            <ContentElement name='Имя' content={<FieldEdit propertyName='analytic/name' value={user.analytic.name} />} />
            <ContentElement name='Доступ' content={<FieldReadOnly value={user.analytic.root} />} />
        </div>;

        return content
    }
    function Administrator(props) {

        const content = <div className={styles.contentNoShadow}>
            <ContentElement name='Имя' content={<FieldEdit propertyName='administrator/name' value={user.administrator.name} />} />
            <ContentElement name='Доступ' content={<FieldReadOnly value={user.administrator.root} />} />
        </div>;

        return content
    }
    function Worker(props) {

        const content = <div className={styles.contentNoShadow}>
            <ContentElement name='Имя' content={<FieldEdit propertyName='worker/name' value={user.worker.name} />} />
            <ContentElement name='Доступ' content={<FieldReadOnly value={user.worker.root} />} />
        </div>;

        return content
    }

    function ReplacePassword(props) {

        const [password, setPassword] = useState('');
        const [newPassword, setNewPassword] = useState('');

        const UpdatePassword = async () => {

            const response = await HttpNoData('/api/v0/account/password?oldPassword=' + password + '&newPassword=' + newPassword, 'PUT', jwt);

            if (response.statusSuccessful) {
                setPassword('');
                setNewPassword('');
                alert('Пароль успешно обновлен');
            }
            else alert('Текущий пароль неверный!');
        }

        return <div className={styles.contentNoShadow}>
            <ContentElement name='Старый пароль' content={<input type='password' className={styles.password} value={password} onChange={(e) => setPassword(e.target.value)} />} />
            <ContentElement name='Новый пароль' content={<input type='password' className={styles.password} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />} />
            <button className={styles.savePassword} disabled={password == '' || newPassword == ''} onClick={UpdatePassword}>сохранить</button>
        </div>
    }

    const Telegram = () => {

        return user.telegram ?
        <FieldReadOnly value={'@'+user.telegram.username}/>
        : <button className={styles.linkConnect}
            onClick={async() => {
                const response = await HttpNoData('/api/v0/telegram/code', 'GET', jwt);
                const link = document.createElement('a');
                console.log(response);
                link.href = `${response.data.link}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }}>Подключить</button>
    }

    return <div className={styles.psevdoBody}>
        <div className={styles.content}>
            <ContentElement name='Имя' content={<FieldEdit propertyName='name' value={user.name} />} />
            <ContentElement name='Логин' content={<FieldEdit propertyName='login' value={user.login} successCallback={(response) => {
                props.updateJwt(response.data);
                setJwt(response.data);
                return response.data;
            }} />} />
            <ContentElement name='Сменить пароль' content={<ReplacePassword />} />
            <ContentElement name='Менеджер' content={user.manager == null ? null : <Manager />} />
            <ContentElement name='Производственный специалист' content={user.worker == null ? null : <Worker />} />
            <ContentElement name='Аналитик' content={user.analytic == null ? null : <Analytic />} />
            <ContentElement name='Администратор' content={user.administrator == null ? null : <Administrator />} />
            <ContentElement name='Сторонние сервисы' content={
                <div className={styles.contentNoShadow}>
                <ContentElement name='Telegram' content={<Telegram/>} />
            </div>
            } />
        </div>
    </div>
}

export default Profile;