import React from "react";
import { HttpNoData } from "../../Core";

export async function getAccount(jwt){

    const response = await HttpNoData('/api/v0/Authentication', 'GET', jwt);

    if(!response.statusSuccessful)
        console.log(response.error)

    return response.data;
}