import React, { useEffect, useState } from "react";
import { FaCommentAlt, FaPen, FaTrash } from 'react-icons/fa';

import { HttpNoData } from "../../../Core";
import styles from '../Style/CurrentShift.module.css';
import CheckListShift from "./CheckListShift";

function CurrentShift() {

    const [isShowCheckList, setIsShowCheckList] = useState(false);
    const [shiftId, setShiftId] = useState(0);
    const [untieMachine, setUntieMachine] = useState(true);
    const [listNoUseWorks, setListNoUseWorks] = useState([]);
    const [listUseWorks, setListUseWorks] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currentCategoryJob, setCurrentCategoryJob] = useState({});
    const [jwt, setJwt] = useState(localStorage.getItem('jwtToken'));
    const [currentPanelHide, setCurrentPanelHide] = useState(true);
    const [currentPanel, setCurrentPanel] = useState({ id: 0, name: '', type: '' });

    const CreateWorkPullItemUI = (jobTitle, jobValue, description, newCurrentIndex) => {

        var newJobTitle = jobTitle;
        var newJobValue = jobValue;

        if (!(newJobTitle && !isNaN(newJobValue)))
            return;

        if (description == 'null')
            description = null;

        return (
            <li key={newCurrentIndex} className={styles.containerLi} id={'job' + newCurrentIndex}>
                <span className={styles.containerText}>
                    <label className={styles.containerNameItem}>{newJobTitle}: {newJobValue}</label>
                    <label className={styles.containerDescriptionItem}>{description}</label>
                </span>
                <span className={styles.containerEvent}>
                    <button className={styles.buttonEdit} onClick={() => { editValue(newCurrentIndex) }}>
                        <FaPen />
                    </button>
                    <button className={styles.buttonEdit} onClick={() => { editDescription(newCurrentIndex) }}>
                        <FaCommentAlt />
                    </button>
                    <button className={styles.buttonDelete} onClick={() => { removeJob(newCurrentIndex); }}>
                        <FaTrash />
                    </button>
                </span>
            </li>
        );
    }

    useEffect(() => {

        async function DidMount() {

            var response = await HttpNoData('/api/v0/shifts/current', 'GET', jwt);

            if (!response.statusSuccessful) {
                window.location.href = "/Employee";
                return;
            }

            var currentShift = response.data;

            setShiftId(currentShift.id);

            if (!(currentShift.machineEnd == null || currentShift.machineEnd == 'null'))
                setUntieMachine(false);

            var responseNoUseWorks = await HttpNoData('/api/v0/extraworks/nouse/sort/categories/' + currentShift.id, 'GET', jwt);

            if (responseNoUseWorks.statusSuccessful) {
                setCategories(responseNoUseWorks.data);
                setListNoUseWorks(responseNoUseWorks.data[0].extraWorks);
            }

            var responseUseWorks = await HttpNoData('/api/v0/ExtraWorkPulls/' + currentShift.id, "GET", jwt);

            if (responseUseWorks.statusSuccessful) {
                setListUseWorks(responseUseWorks.data.map((work, index) => ({ id: ('job' + work.id), value: work })));
            }
        }

        DidMount();

    }, []);

    const editValue = (jobId) => {
        let currentJobIndex = "job" + jobId;
        let currentJob = listUseWorks.find((value, index, array) => { return value.id == currentJobIndex }).value;

        setCurrentPanelHide(false);
        var inputFieldUp = document.getElementById('inputFieldUp');
        inputFieldUp.value = currentJob.value;
        inputFieldUp.focus();
        setCurrentPanel({ id: currentJob.id, name: currentJob.name, value: currentJob.value, type: 'value' });
    }

    const editDescription = (jobId) => {

        let currentJobIndex = "job" + jobId;
        let currentJob = listUseWorks.find((value, index, array) => { return value.id == currentJobIndex }).value;

        setCurrentPanelHide(false);
        var inputFieldUp = document.getElementById('inputFieldUp');
        inputFieldUp.value = currentJob.description;
        inputFieldUp.focus();

        setCurrentPanel({ id: currentJob.id, name: currentJob.name, value: currentJob.description, type: 'description' });
    }

    //{ extraworkpullid: work.id, extraworkid: work.extraWorkId }
    const removeJob = async (jobId) => {

        if(!window.confirm('Уверены что хотите удалить?'))
            return;

        let currentJobIndex = listUseWorks.findIndex((value, index, array) => { return value.id == ('job' + jobId) });
        let currentJob = listUseWorks[currentJobIndex];

        var response = await HttpNoData('/api/v0/ExtraWorkPulls/' + shiftId + "/" + currentJob.value.id, "DELETE", jwt);

        if (response.statusSuccessful) {

            setListUseWorks(listUseWorks.filter((item) => (+item.value.id !== +currentJob.value.id)));

            var newList = [...listNoUseWorks, { id: currentJob.value.extraWorkId, name: currentJob.value.name }];
            var temp = currentCategoryJob;
            temp.extraWorks = newList;
            setCurrentCategoryJob(temp);
            setListNoUseWorks(newList);
        } else {
            console.log(response.error);
        }
    }


    const addJob = async () => {

        var jobSelect = document.getElementById("jobSelect");
        var newJobTitle = jobSelect.options[jobSelect.selectedIndex].textContent;
        let workId = jobSelect.value;

        let jobValueUI = document.getElementById("newJobValue");
        let jobDescriptionUI = document.getElementById('newJobDescription');

        var newJobValue = jobValueUI.value;

        if (newJobTitle && !isNaN(newJobValue) && newJobValue > 0) {

            let shiftid = shiftId;

            var response = await HttpNoData('/api/v0/ExtraWorkPulls/' + shiftid + "/" + workId + "/" + newJobValue + "/?description=" + jobDescriptionUI.value, "POST", jwt);

            if (response.statusSuccessful) {
                var data = response.data;

                var index = data.id;

                setListUseWorks([...listUseWorks, { id: ('job' + index), value: data }]);

                var newList = listNoUseWorks.filter((item) => (+item.id !== +workId));
                var temp = currentCategoryJob;
                temp.extraWorks = newList;
                setCurrentCategoryJob(temp);
                setListNoUseWorks(newList);

                jobValueUI.value = "";
                jobDescriptionUI.value = "";
            }
            else {
                console.log(response.error);
            }
        }
    }


    const finishShift = async () => {

        var response = await HttpNoData('/api/v0/shifts/current', "DELETE", jwt);

        if (response.statusSuccessful)
            window.location.href = "/Employee/timeSpan/verificationCode?status=close";
        else {
            console.log(response.error);
        }
    }

    const ClickUntieMachine = async () => {
        var response = await HttpNoData('/api/v0/shifts/current/untiemachine', 'HEAD', jwt);

        if (response.statusSuccessful)
            setUntieMachine(false);
        else {
            console.log(response.error);
        }
    }

    const SaveEdit = async () => {

        var oldValue = listUseWorks.find((value, i, array) => { return +value.value.id == +currentPanel.id }).value.value;
        var jobValue = document.getElementById('inputFieldUp').value;

        if (oldValue == jobValue) {

            setCurrentPanelHide(true);
            setCurrentPanel(currentPanel);
            return;
        }

        if (currentPanel.type == 'value') {

            if (!isNaN(jobValue) && jobValue > 0) {

                var response = await HttpNoData('/api/v0/ExtraWorkPulls/updateValue/' + shiftId + "/" + currentPanel.id + "/" + jobValue, "PUT", jwt);

                if (response.statusSuccessful) {

                    const updatedList = listUseWorks;
                    const index = updatedList.findIndex(item => +item.value.id == +currentPanel.id);

                    const updatedItem = updatedList[index];

                    updatedItem.value.value = jobValue;
                    updatedList[index] = updatedItem;

                    setListUseWorks(updatedList);
                    setCurrentPanelHide(true);
                }
                else {
                    console.log(response.error);
                }
            }
        }
        else {

            var response = await HttpNoData('/api/v0/ExtraWorkPulls/updateDescription/' + shiftId + "/" + currentPanel.id + "/?newDescription=" + jobValue, "PUT", jwt);

            if (response.statusSuccessful) {
                const updatedList = listUseWorks;
                const index = updatedList.findIndex(item => +item.value.id === +currentPanel.id);

                const updatedItem = updatedList[index];

                updatedItem.value.description = jobValue;
                updatedList[index] = updatedItem;

                setListUseWorks([...updatedList]);
                setCurrentPanelHide(true);
            }
            else {
                console.log(response.error);
            }
        }
    }

    const NoSaveEdit = () => {
        setCurrentPanelHide(true);
    }

    const CheckSelectCategory = (event) => {

        var current = categories[categories.findIndex(item => +item.id === +event.target.value)];
        setCurrentCategoryJob(current);
        setListNoUseWorks(current.extraWorks);
    }

    const pulls = listUseWorks.map((work, i) => CreateWorkPullItemUI(work.value.name, work.value.value, work.value.description, work.value.id));
    const categoriesUI = categories.map((category) => <option key={category.id} value={category.id}>{category.name}</option>)
    const works = listNoUseWorks.map((work, i) => <option key={work.id} value={work.id}>{work.name}</option>);

    return (
        <div className={styles.psevdoBody}>
            <div className={styles.containerMain}>
                <h1 className={styles.customH1}>Смена</h1>

                <h2 className={styles.customH2}>Список данных</h2>
                <ul className={styles.containerUl} id="containerUl">
                    {pulls}
                </ul>

                <h2 className={styles.customH2}>Добавить новую работу</h2>
                <form className={styles.customForm} id="newJobForm">
                    Выберите категорию работы:
                    <select className={styles.customSelect} onChange={CheckSelectCategory} id="jobSelectCategories">
                        {categoriesUI}
                    </select>
                    Выберите название работы:
                    <select className={styles.customSelect} id="jobSelect">
                        {works}
                    </select>
                    <br />
                    Значение: <input type="number" className={styles.containerJobValue} id="newJobValue" />
                    <br />
                    Описание: <input type="text" className={styles.containerJobDescription} id="newJobDescription" />
                    <br />
                    <button className={styles.customButton} type="button" onClick={addJob}>Добавить</button>
                </form>

                <div>
                    <h5>Отвязать станки</h5>
                    <button className={(untieMachine ? styles.buttonRed : styles.buttonDisabled) + ' ' + styles.customButton} disabled={!untieMachine} id="untieMachine" onClick={ClickUntieMachine}>Отвязать станки</button>
                </div>

                <div>
                    <h2 className={styles.customH2}>Завершить смену</h2>
                    <button className={styles.buttonRed + ' ' + styles.customButton} onClick={() => setIsShowCheckList(!isShowCheckList)}>Завершить смену</button>
                </div>
            </div>

            {isShowCheckList ?
                <div className={styles.checkListShiftBody}>
                    <CheckListShift childs={() => <div>
                        <button className={styles.buttonRed + ' ' + styles.customButton} onClick={finishShift}>закрыть</button>
                        <button className={styles.checkList + ' ' + styles.customButton} onClick={() => setIsShowCheckList(!isShowCheckList)}>отмена</button>
                    </div>} className={styles.checkListShift} shiftId={shiftId} works={listUseWorks} jwt={jwt}/>
                </div> : null
            }

            <div hidden={currentPanelHide} id="panelUp" className={styles.boxPanelUp}>
                <div className={styles.boxField}>
                    <label id="labelFieldUp" className={styles.nameFieldEdit}>{currentPanel.name}</label>
                    <input id="inputFieldUp" className={styles.inputFieldEdit} />
                    <button className={styles.buttonComplete + ' ' + styles.customButton} onClick={SaveEdit}>Сохранить</button>
                    <button className={styles.buttonCancel + ' ' + styles.customButton} onClick={NoSaveEdit}>Отменить</button>
                </div>
            </div>
        </div>
    );
}

export default CurrentShift;