Object.prototype.correctDateType = function () {
	const options = {
		hour: 'numeric',
		minute: 'numeric',
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	}
	let correctStartDate = new Date(this.startDate).toLocaleDateString(
		'ru-RU',
		options
	)
	let correctEndDate = new Date(this.endDate).toLocaleDateString(
		'ru-RU',
		options
	)
	return {
		...this,
		startDate: correctStartDate,
		endDate: correctEndDate,
	}
}

Object.prototype.correctDbDateType = function () {
	let correctStartDate = new Date(this.startDate)

	correctStartDate = new Date(
		correctStartDate.getTime() + correctStartDate.getTimezoneOffset() * 60000
	)

	let correctEndDate = new Date(this.endDate)
	correctEndDate = new Date(
		correctEndDate.getTime() + correctEndDate.getTimezoneOffset() * 60000
	)

	return {
		...this,
		startDate: correctStartDate,
		endDate: correctEndDate,
	}
}

export const notificationPriorityList = [
	{
		id: 0,
		name: 'информационное',
	},
	{
		id: 1,
		name: 'напоминающее',
	},
	{
		id: 2,
		name: 'предупредительное',
	},
]
