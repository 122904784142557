import React from "react";
import styles from './IconLoad.module.css';

function IconLoad(props){

    return <div className={styles.cssload_loader + ' ' + props.className}>
        <ul className={styles.customUl}>
          <li className={styles.customLi}></li>
          <li className={styles.customLi}></li>
          <li className={styles.customLi}></li>
          <li className={styles.customLi}></li>
          <li className={styles.customLi}></li>
          <li className={styles.customLi}></li>
        </ul>
      </div>
}

export default IconLoad;