import React from "react";
import { HttpNoData } from "../../Core";

export async function getLeadRange(start, end, jwt){

    const response = await HttpNoData('/api/v0/analyze/leads/range?start=' + start + "&end=" + end, 'GET', jwt);

    if(!response.statusSuccessful)
        console.log(response.error)

    return response.data;
}