import React, { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { getRandomColor } from "../../../Core";
import styles from '../Styles/AnalyzeProducts.module.css';
import { ProductOnDate } from "../../../apiMethod/analyze";
import { getCategories, getProducts } from "../../../apiMethod/products";
import { Alert, Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import { FaCircle, FaChevronLeft, FaChevronRight } from "react-icons/fa";

const dayOfWeek = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];
const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

function AnalyzeProducts({ jwt }) {

    const updateColors = (array) => {
        array.map(o => {
            o.color = getRandomColor();
            return o;
        });

        return array;
    }

    const [products, setProducts] = useState(null);
    const [productsSend, setProductsSend] = useState(null);
    const [currentDateIndex, setCurrentDateIndex] = useState(0);
    const [categories, setCategories] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
    const [ranges] = useState([
        { name: 'По дням', value: 'days' },
        { name: 'По неделям', value: 'weeks' },
        { name: 'По месяцам', value: 'months' },
        { name: 'По годам', value: 'years' }
    ]);
    const [radioValue, setRadioValue] = useState('days');
    const [currentDate, setCurrentDate] = useState(new Date());
    const [sumAll, setSumAll] = useState(-10);

    useEffect(() => {

        async function didMount() {

            await updateTimeSpanValue(radioValue, null);

            const responseCategories = await getCategories(jwt);

            const products = await getProducts(jwt);

            if (!products || !responseCategories)
                return;

            setCategories(responseCategories);
            setProducts(updateColors(products));
        }

        didMount();
    }, []);

    window.addEventListener('resize', async function (event) {
        setIsMobile(window.innerWidth <= 640);
    }, true);

    const updateTimeSpanValue = async (value, date) => {
        let response = await ProductOnDate(value, date?.toJSON().split('T')[0], jwt);

        if (response && value !== 'weeks') {
            response = response.map(o => {
                if (value === 'days')
                    o.date = dayOfWeek[+o.date];
                else if (value === 'months')
                    o.date = months[+o.date - 1];
                else if (value === 'years')
                    o.date += ' год'

                return o;
            });

        }

        setCurrentDate(date ? date : new Date());

        if(response){

            if(response.length > 0)
                setSumAll(response.map(o => o.sum).reduce((a, o) => a + o));
            else setSumAll(0);

            setProductsSend(response);
        } else {
            setSumAll(0);
            setProductsSend([]);
        }
        setRadioValue(value);
    }

    const getDateTimeMove = (date, isLeft) => {

        if (radioValue === 'years')
            return new Date(new Date(date).setFullYear(date.getFullYear() - (isLeft ? 5 : -5)));

        if(radioValue === 'months')
            return new Date(new Date(date).setFullYear(date.getFullYear() - (isLeft ? 1 : -1)));

        if(radioValue === 'weeks')
            return new Date(new Date(date).setDate(date.getDate() - (isLeft ? 35 : -35)));

        if(radioValue === 'days')
            return new Date(new Date(date).setDate(date.getDate() - (isLeft ? 7 : -7)));

        return new Date();
    }

    if (!products || !categories)
        return <Alert variant="danger" dismissible>
            <Alert.Heading>Ох, щелк! У вас ошибка!</Alert.Heading>
            <p>
                Что-то пошло не по плану, попробуйте перезагрузить страницу! Если не помогло тогда сообщите разработчику и дождитесь обновления.
            </p>
        </Alert>;

    const bars = products.map((item, index) =>
        <Bar onClick={(data, dateIndex) => setCurrentDateIndex(dateIndex)} key={item.id} dataKey={(data, index) => {

            const result = data?.products.find(o => o.productId == item.id);
            return result ? result.value : 0;
        }} stackId={1} name={item.name} fill={item.color} >
            {index === products.length - 1 ? <LabelList position="top" fontSize={14} dataKey={'sum'} /> : null}
        </Bar>);

    const ViewProduct = ({ product }) => {

        const [color, setColor] = useState(product.color);

        const currentDate = productsSend[currentDateIndex] ?? [];
        const value = currentDate.products?.find(o => o.productId == product.id);

        return <button onClick={() => {
            const newColor = getRandomColor();
            product.color = newColor;
            setColor(newColor);
            setProducts([...products]);
        }}
            className={styles.element} key={product.id}>
            <FaCircle style={{ width: 10, color: color }} />
            <label className={styles.elementText}>
                {product.name}: <strong>{value ? value.value : 0}</strong>
            </label>

        </button>
    }

    const Views = ({ products }) => {
        return products.map((product) => <ViewProduct key={product.id} product={product} />);
    }

    const ViewDateMove = ({ date }) => {

        if (productsSend.length <= 0)
            return 'загрузка';

        if (radioValue === 'years')
            return `${productsSend[0].date.split(' ')[0]} - ${productsSend[productsSend.length - 1].date.split(' ')[0]}`;

        if (radioValue === 'months')
            return date.toLocaleString('default', { year: 'numeric' });

        if (radioValue === 'weeks')
            return `${productsSend[0].startWeek.split(' ')[0]} - ${productsSend[productsSend.length - 1].endWeek.split(' ')[0]}`;

        if (radioValue === 'days')
            return `${productsSend[0].dateFormat.split(' ')[0]} - ${productsSend[productsSend.length - 1].dateFormat.split(' ')[0]}`;

        return 'ошибка';
    }

    return <div className={styles.main}>
        <div className={styles.content}>
            <div className={styles.headContent}>
                <label className={styles.titleChart}>Проданная продукция</label>
                <div className={styles.dateBox}>
                    <ButtonGroup aria-label="Basic example">
                        <Button variant="primary" onClick={() => updateTimeSpanValue(radioValue, getDateTimeMove(currentDate, true))}>
                            <FaChevronLeft style={{color: 'white'}}/>
                        </Button>
                        <div className={styles.dateMiddleText}>
                            <ViewDateMove date={currentDate} />
                        </div>
                        <Button variant="primary" onClick={() => updateTimeSpanValue(radioValue, getDateTimeMove(currentDate, false))}>
                            <FaChevronRight style={{color: 'white'}}/>
                        </Button>
                    </ButtonGroup>
                </div>
                <div className={styles.dateBox}>
                    <ButtonGroup>
                        {
                            ranges.map((radio, idx) => (
                                <ToggleButton
                                    className={styles.radio}
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    name="radio"
                                    variant='outline-primary'
                                    value={radio.value}
                                    checked={radioValue === radio.value}
                                    onChange={(e) => updateTimeSpanValue(e.currentTarget.value, null)}
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                    </ButtonGroup>
                </div>
            </div>
            <ResponsiveContainer width={'100%'} height={isMobile ? 400 : 500}>
                <BarChart data={productsSend} margin={{ top: 20 }}>

                    {
                        isMobile ? null :
                            <React.Fragment>
                                <XAxis dataKey="date" stroke="#8884d8" fontSize={12} />
                                <YAxis width={35} fontSize={9} />
                            </React.Fragment>
                    }
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    {
                        bars
                    }
                </BarChart>
            </ResponsiveContainer>
            <div className={styles.currentDateText}>
                {productsSend[currentDateIndex]?.date} - {Math.round((productsSend[currentDateIndex]?.sum / sumAll) * 10000) / 100}% (от {sumAll})
            </div>
            <div className={styles.elements}>
                {
                    categories.map(c => <div className={styles.categoryItem} key={c.id}>
                        <div className={styles.categoryName}>{c.name.toLocaleUpperCase()}</div>
                        <Views products={products.filter(o => o.categoryId == c.id)} />
                    </div>)
                }
            </div>
        </div>
    </div>
};

export default AnalyzeProducts;