import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
const EditCategoryView = ({
	show,
	setShow,
	data,
	setData,
	jwt,
	workCategories,
}) => {
	const [allChecked, setAllChecked] = useState(false)

	const [notificationData, setNotificationData] = useState(data)

	useEffect(() => {
		setNotificationData(data)
	}, [data])

	return (
		<Modal show={show}>
			<Modal.Header closeButton>
				<b>Выберите категории работ</b>
			</Modal.Header>
			<Modal.Body>
				<Form>
					{workCategories && workCategories.length > 0 ? (
						<>
							<Form.Check
								label='Все'
								name='all'
								onChange={e => {
									const { checked } = e.target
									setAllChecked(checked)
									if (checked) {
										setNotificationData({
											...notificationData,
											workerCategoriesIdes: [],
										})
									} else {
										setNotificationData({
											...notificationData,
											workerCategoriesIdes: [],
										})
									}
								}}
							/>
							{workCategories.map(o => (
								<Form.Check
									key={o.id}
									label={o.name}
									name={o.id}
									checked={
										(notificationData.workerCategoriesIdes &&
											notificationData.workerCategoriesIdes.includes(o.id)) ||
										(allChecked &&
											(!notificationData.workerCategoriesIdes ||
												notificationData.workerCategoriesIdes.length === 0))
									}
									onChange={e => {
										const { checked } = e.target
										if (checked) {
											setNotificationData({
												...notificationData,
												workerCategoriesIdes: [
													...(notificationData.workerCategoriesIdes || []),
													o.id,
												],
											})
										} else {
											setNotificationData({
												...notificationData,
												workerCategoriesIdes: (
													notificationData.workerCategoriesIdes || []
												).filter(id => id !== o.id),
											})
										}
										setAllChecked(false)
									}}
								/>
							))}
						</>
					) : (
						<p>Упс... категории работ не загрузились</p>
					)}
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={() => setShow(false)}>
					Закрыть
				</Button>
				<Button
					variant='primary'
					type='submit'
					onClick={() => {
						setShow(false)
						setData(notificationData)
					}}
				>
					Сохранить
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default EditCategoryView
