import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import EditCategoryView from '../EditCategoryView/EditCategoryView.jsx'
import EditWorkersView from '../EditWorkersView/EditWorkersView.jsx'
import '../Utils/utils.js'
import { notificationPriorityList } from '../Utils/utils.js'
const NotificationEditView = ({
	jwt,
	show,
	setShow,
	data,
	handleSave,
	editNotification,
	workers,
	workCategories,
}) => {
	const [notificationData, setNotificationData] = useState(data)
	const [validated, setValidated] = useState(false)

	const [notificationTypes, setNotificationTypes] = useState()
	const [showCategoryModal, setShowCategoryModal] = useState(false)
	const [showWorkersModal, setShowWorkersModal] = useState(false)

	const handleSubmit = event => {
		const form = event.currentTarget
		event.preventDefault()
		if (form.checkValidity() === false) {
			event.stopPropagation()
		}

		if (form.checkValidity()) {
			handleSave(notificationData.correctDateType())
			editNotification(notificationData.correctDateType())
			handleClose()
		}

		setValidated(notificationData)
	}

	const handleClose = () => {
		setShow(false)
	}

	return (
		<>
			{showCategoryModal ? (
				<EditCategoryView
					workCategories={workCategories}
					jwt={jwt}
					data={data}
					show={showCategoryModal}
					setShow={setShowCategoryModal}
					setData={setNotificationData}
				/>
			) : (
				''
			)}

			{showWorkersModal ? (
				<EditWorkersView
					workers={workers}
					jwt={jwt}
					data={data}
					show={showWorkersModal}
					setShow={setShowWorkersModal}
					setData={setNotificationData}
				/>
			) : (
				''
			)}

			<Modal show={show} onHide={() => setShow(false)}>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title>Редактировать уведомление</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group className='mb-3'>
							<Form.Label>Тип</Form.Label>
							<Form.Select
								value={notificationData?.types}
								onChange={e =>
									setNotificationData({
										...notificationData,
										types: e.target.value,
									})
								}
							>
								{notificationTypes && notificationTypes.length > 0 ? (
									notificationTypes.map((o, id) => (
										<option value={o.key} key={o.id}>
											{o.key}
										</option>
									))
								) : (
									<option>Упс... Типы уведомлений не подгрузились</option>
								)}
							</Form.Select>
							<Form.Control.Feedback type={'invalid'}>
								Обязательно
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Приоритет</Form.Label>
							<Form.Select
								value={notificationData?.priority}
								onChange={e =>
									setNotificationData({
										...notificationData,
										priority: e.target.value,
									})
								}
							>
								{notificationPriorityList.map((o, id) => (
									<option value={o.id} key={id}>
										{o.name}
									</option>
								))}
							</Form.Select>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Активно</Form.Label>
							<Form.Select
								value={notificationData?.isAction}
								onChange={e =>
									setNotificationData({
										...notificationData,
										isAction: e.target.value,
									})
								}
							>
								<option value='false'>Нет</option>
								<option value='true'>Да</option>
							</Form.Select>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Дата начала</Form.Label>
							<Form.Control
								type='datetime-local'
								value={notificationData?.startDate}
								onChange={e =>
									setNotificationData({
										...notificationData,
										startDate: e.target.value,
									})
								}
							></Form.Control>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Дата конца</Form.Label>
							<Form.Control
								type='datetime-local'
								value={notificationData?.endDate}
								onChange={e =>
									setNotificationData({
										...notificationData,
										endDate: e.target.value,
									})
								}
							></Form.Control>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Работники</Form.Label>
							<br></br>
							<Button variant='info' onClick={() => setShowWorkersModal(true)}>
								Редактировать работников
							</Button>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Категории работ</Form.Label>
							<br></br>
							<Button variant='info' onClick={() => setShowCategoryModal(true)}>
								Редактировать категории работ
							</Button>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Сообщение</Form.Label>
							<Form.Control
								required
								as='textarea'
								rows={3}
								value={notificationData?.message}
								onChange={e =>
									setNotificationData({
										...notificationData,
										message: e.target.value,
									})
								}
							></Form.Control>
							<Form.Control.Feedback type={'invalid'}>
								Обязательно
							</Form.Control.Feedback>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={() => setShow(false)}>
							Закрыть
						</Button>
						<Button variant='primary' type='submit'>
							Сохранить
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	)
}

export default NotificationEditView
