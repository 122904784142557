import { Route, Routes } from "react-router-dom";
import AnalyzeOrder from './Pages/AnalyzeOrder';
import Home from "./Pages/HomePage";
import AnalyzeProducts from "./Pages/AnalyzeProducts";
import FormulasMath from "./Pages/FormulasMath";

function NavBar({ jwt }){
    
    return <div>
    <Routes>
      <Route path="/*" element={<Home/>} />
      <Route path="/dynamicOrder" element={<AnalyzeOrder jwt={jwt}/>}/>
      <Route path="/sell" element={<AnalyzeProducts jwt={jwt}/>}/>
      <Route path="/profitability" element={<FormulasMath jwt={jwt}/>}/>
    </Routes>
  </div>;
}

export default NavBar;