import React, { useEffect, useState } from "react";
import { HttpData, HttpNoData } from "../../Core";
import styles from './Feedback.module.css';

function Feedback(props) {

    const [feedbacks, setFeedbacks] = useState([]);
    const [feedbacksFilter, setFeedbacksFilter] = useState([]);
    const [jwt, setJwt] = useState(props.jwt);
    const [categories, setCategories] = useState([]);
    const [currentCategory, setCurrentCategory] = useState(1);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [categoryFilter, setCategoryFilter] = useState(-1);

    useEffect(() => {

        async function DidMount() {
            const responseAll = await HttpNoData('/api/v0/feedback/all', 'GET', jwt);

            if (responseAll.statusSuccessful) {
                setFeedbacks(responseAll.data);
                setFeedbacksFilter(responseAll.data);
            }
            const responseCategories = await HttpNoData('/api/v0/feedback/categories', 'GET', jwt);

            if (responseCategories.statusSuccessful)
                setCategories(responseCategories.data);
        }

        DidMount();
    }, []);

    const Create = async () => {

        if (message == '' || message == null || title == null || title == '') {
            alert('Поля не могут быть пустыми');
            return null;
        }

        const response = await HttpData('/api/v0/feedback', 'POST', jwt, { categoryId: currentCategory, title: title, message: message });

        if (response.statusSuccessful) {
            const updateArray = [...feedbacks, response.data];
            setFeedbacks(updateArray);
            setFeedbacksFilter(updateArray);
            setMessage('');
            setTitle('');
        }
    }

    const Filter = (id) => {

        const categoryId = id == categoryFilter ? -1 : +id;
        setCategoryFilter(categoryId);

        if (categoryId > 0)
            setFeedbacksFilter(feedbacks.filter(item => +item.categoryId === +id));
        else setFeedbacksFilter(feedbacks);
    }

    return <div className={styles.psevdoBody}>
        <div className={styles.content}>
            <div className={styles.formFeedback}>
                <div className={styles.inputModel}>
                    <label className={styles.titleModel}>Заголовок</label>
                    <input type="text" className={styles.inputTitle} value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className={styles.inputModel}>
                    <label className={styles.titleModel}>Категория</label>
                    <select type="text" className={styles.inputTitle} value={currentCategory} onChange={(e) => setCurrentCategory(e.target.value)}>
                        {
                            categories.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                        }
                    </select>
                </div>
                <div className={styles.inputModel}>
                    <label className={styles.titleModel}>Сообщение</label>
                    <textarea className={styles.inputMessage} value={message} onChange={(e) => setMessage(e.target.value)} />
                </div>
                <button className={styles.send} onClick={Create}>Отправить</button>
            </div>
            <div className={styles.filters}>
                {
                    categories.map(item => <button key={item.id} onClick={() => Filter(item.id)} className={+categoryFilter == item.id ? styles.filterUse : styles.filter}>{item.name}</button>)
                }
            </div>
            <div className={styles.feedbacks}>
                {
                    feedbacksFilter.length <= 0 ?
                        <div className={styles.emptyFeedbacks}>
                            Будь первым!
                        </div>
                        :
                        feedbacksFilter.map((item, index) => <div key={index} className={styles.feedback}>
                            <div className={styles.headFeedback}>
                                <label className={styles.title}>{item.title}</label>
                                <label className={styles.author}>{item.author}</label>
                            </div>
                            <div className={styles.message}>{item.message}</div>
                        </div>)
                }
            </div>
        </div>
    </div>;
}

export default Feedback;