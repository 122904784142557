import React, { useEffect, useState } from 'react';
import { Area, Bar, CartesianGrid, ComposedChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styles from '../Styles/AnalyzeOrder.module.css';
import { Alert, Table } from 'react-bootstrap';
import { getManagers } from '../../../apiMethod/managers';
import { getDataDay } from '../../../apiMethod/analyze';
import { getLeadRange } from '../../../apiMethod/analytic/analyzeLead';

function AnalyzeOrder({ jwt }) {
    
    const [dataOnLeads, setDataOnLeads] = useState();
    const [currentDateStart, setCurrentDateStart] = useState(new Date((new Date()).setDate((new Date()).getDate() - 30)).toLocaleDateString().split('.').reverse().join('-'));
    const [currentDateEnd, setCurrentDateEnd] = useState(new Date().toLocaleDateString().split('.').reverse().join('-'));
    const [managers, setManagers] = useState([]);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);

    useEffect(() => {

        async function didMount() {

            const rangeData = await getLeadRange(currentDateStart, currentDateEnd, jwt);
            setDataOnLeads(rangeData);

            const managers = await getManagers(jwt);
            setManagers(managers ?? []);
        }

        didMount();
    }, []);

    window.addEventListener('resize', async function (event) {
        setIsMobile(window.innerWidth <= 640);
    }, true);

    const UpdateCurrentDateStart = async (date) => {
        const rangeData = await getLeadRange(date, currentDateEnd, jwt);

        setCurrentDateStart(date);
        setDataOnLeads(rangeData);
    }

    const UpdateCurrentDateEnd = async (date) => {
        const rangeData = await getLeadRange(currentDateStart, date, jwt);

        setCurrentDateEnd(date);
        setDataOnLeads(rangeData);
    }

    const ReChartMain = () => {

        const [dataDay, setDataDay] = useState();

        return <React.Fragment>
            <div className={styles.contentChart}>
                <ResponsiveContainer width={'100%'} height={isMobile ? 200 : 300}>
                    <ComposedChart margin={{ top: 15 }} data={dataOnLeads.data}>
                        <XAxis dataKey="day" stroke="#8884d8" fontSize={isMobile ? 9 : 12} />
                        <YAxis width={30} fontSize={9} />
                        <Tooltip />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <Legend />
                        <Bar onClick={async (data) => {
                            const response = await getDataDay(data.day.split('.').reverse().join('-'), jwt);
                            setDataDay(response);
                        }} dataKey={(record, index) => +record.value2.toFixed(0)} name={`${dataOnLeads.value2.name} - ${dataOnLeads.data.reduce((a, b) => a + b.value2, 0).toFixed(2)}`} fill="#8884d8">
                            {
                                isMobile ? null :
                                    <LabelList fontSize={9} position="top" />}
                        </Bar>
                        <Bar onClick={async (data) => {
                            const response = await getDataDay(data.day.split('.').reverse().join('-'), jwt);
                            setDataDay(response);
                        }} dataKey={(record, index) => +record.value1.toFixed(1)} name={`${dataOnLeads.value1.name} - ${dataOnLeads.data.reduce((a, b) => a + b.value1, 0).toFixed(2)}`} fill="#4884d8"  >
                            {
                                isMobile ? null :
                                    <LabelList fontSize={9} position="top" />}
                        </Bar>
                    </ComposedChart>
                </ResponsiveContainer>
            </div>

            {
                dataDay && dataDay.length > 0 ?
                    <div className={styles.contentChart}>
                        <Table border={false}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Имя</th>
                                    <th>{dataOnLeads.value1.name}</th>
                                    <th>{dataOnLeads.value2.name}</th>
                                </tr>
                            </thead>
                            <tbody >
                            {

                                dataDay.map((o, i) => {

                                    const currentUserName = managers.find(f => f.id == o.managerId)?.name;

                                    return <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{currentUserName ?? "Не найдено"}</td>
                                            <td>{o.value1}</td>
                                            <td>{o.value2}</td>
                                        </tr>
                                    ;
                                })
                            }
                            </tbody>

                        </Table>
                    </div> : null
            }

        </React.Fragment>
    }

    if (!dataOnLeads)
        return <Alert variant="danger" dismissible>
            <Alert.Heading>Ох, щелк! У вас ошибка!</Alert.Heading>
            <p>
                Что-то пошло не по плану, попробуйте перезагрузить страницу! Если не помогло тогда сообщите разработчику и дождитесь обновления.
            </p>
        </Alert>;

    return (
        <div className={styles.psevdoBody}>
            <div className={styles.content}>
                <div className={styles.headContent}>
                    <label className={styles.titleChart}>Динамика заказов</label>
                    <div className={styles.dateBox}>
                        <input style={{ marginRight: 10 }} className={styles.selectDate} type='date' value={currentDateStart} onChange={(e) => { UpdateCurrentDateStart(e.target.value); }} />
                        <input className={styles.selectDate} type='date' value={currentDateEnd} onChange={(e) => { UpdateCurrentDateEnd(e.target.value); }} />
                    </div>
                </div>

                {
                    (dataOnLeads && dataOnLeads.data) ?
                        <React.Fragment>
                            <ReChartMain />

                            <div className={styles.headContent}>
                                <label className={styles.titleChart}>Скользящий объем заявок</label>
                            </div>
                            <div className={styles.contentChart}>
                                <ResponsiveContainer width={'100%'} height={isMobile ? 200 : 300}>
                                    <ComposedChart margin={{ top: 15 }} data={dataOnLeads.data}>
                                        <XAxis dataKey="day" stroke="#8884d8" fontSize={isMobile ? 9 : 12} />
                                        <YAxis width={30} fontSize={9} />
                                        <Tooltip />
                                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                        <Area type="monotone" dataKey='value3' name='скользящий объем заявок' stroke="#8884d8" fill="#8884d8" />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </div>
                        </React.Fragment>
                        : null
                }
            </div>
        </div>
    );
}

export default AnalyzeOrder;
