import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../Manager/Page/Home.js';
import Lead from './Page/Lead.js';
import SellProduct from './Page/SellProdcut.js';

function NavbarManager(props) {

  return (
    <div>
      <Routes>
        <Route path="/*" element={<HomePage jwt={props.jwt}/>} />
        <Route path='/sellProduct' element={<SellProduct/>}/>
        <Route path='/lead' element={<Lead/>}/>
      </Routes>
    </div>
  );
}

export default NavbarManager;