import React, { Component } from "react";
import { HttpNoData } from "../../../Core";
import styles from '../Style/VerificationCode.module.css';

class VerfiicationCode extends Component {

    constructor() {
        super();

        var urlParams = new URLSearchParams(window.location.search);

        this.state = {
            code: 0,
            jwt: localStorage.getItem('jwtToken'),
            status: urlParams.get('status')
        }
    }

    ClickCode = async () => {

        var codeInput = document.getElementById('codeInput').value;
        let status = this.state.status;

        if (!(!isNaN(codeInput) && codeInput.length == 4)) {
            console.log("not integer");
            return;
        }

        var response = await HttpNoData('/api/v0/shifts/current/' + this.state.status + '/verificationCode=' + codeInput, (status == 'open' ? "POST":'DELETE'), this.state.jwt);

        if (!response.statusSuccessful) {
            alert('Убедитесь что ваш код есть на экране!');
            return;
        }


        if (status == 'open') {
            window.location.href = '/Employee/shifts/current';
        }
        else if (status == 'close') {
            window.location.href = '/Employee';
        }
    }
    
    render() {
        console.log(this.state.status);
        return (
            <div className={styles.container_main}>
                <h1 className={styles.customH1}>Код размещен на экране в главном зале</h1>
                <input className={styles.codeInput} type="text" id="codeInput" maxLength="4" placeholder="Введите код (4 цифры)" />
                <button className={styles.submitBtn} id="submitBtn" onClick={this.ClickCode}>Отправить</button>
            </div>
        );
    }
}

export default VerfiicationCode