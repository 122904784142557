import React, { Component } from "react";
import { HttpNoData } from "../../Core";
import styles from './Codes.module.css';

class Codes extends Component {

    constructor() {
        super();

        this.state = {
            timeUpdate: 5,
            jwt: ''
        };
    }

    CreateCodeUI(containerData, worker, status, textStatus) {

        var containerItem = document.createElement('p');
        containerItem.className = styles.customP;

        var containerStatus = document.createElement('span');
        containerStatus.className = status;
        containerStatus.textContent = textStatus;
        containerItem.appendChild(containerStatus);

        var containerName = document.createElement('span');
        containerName.className = styles.name;
        containerName.textContent = worker.worker;
        containerItem.appendChild(containerName);

        var containerValue = document.createElement('span');
        containerValue.className = styles.code;
        containerValue.textContent = worker.code;
        containerItem.appendChild(containerValue);

        containerData.appendChild(containerItem);
    }


    componentDidMount(){

        this.setState({ jwt: localStorage.getItem('jwtToken')});

        this.timer1 = setInterval(() => {
           var timeUpdate = this.state.timeUpdate;

            if (timeUpdate > 0)
              this.setState({ timeUpdate: timeUpdate -= 1 });
            else{
                this.PingServer();
                this.setState({ timeUpdate: 5 });
            }
        }, 1000);
    }

    PingServer = async() => {
            var response = await HttpNoData('/api/v0/shifts/current/codes', 'GET', this.state.jwt);

            if (response.statusSuccessful) {

                var data = response.data;
                var containerData = document.getElementById('container-data');
                containerData.innerHTML = '';

                var opens = data.opens;
                var closes = data.closes;

                if (opens != null && opens.length > 0) {

                    opens.forEach(worker => {
                        this.CreateCodeUI(containerData, worker, styles.statusOpen, 'OPEN');
                    })
                }

                if (closes != null && closes.length > 0) {

                    closes.forEach(worker => {
                        this.CreateCodeUI(containerData, worker, styles.statusClose, 'CLOSE');
                    });
                }

                this.setState({ timeUpdate: 5 });
            }
            else {
                console.error(response);
            }
    }

    componentWillUnmount(){
        clearInterval(this.timer1);
    }

    render() {
        const timeUpdate = this.state.timeUpdate;

        return (
            <div className={styles.name_code_container}>
                <progress className={styles.progressBar} max="100" value={timeUpdate * 20}/>
                <h1 className={styles.customH1}>Статус-ИМЯ-код</h1>

                <div id="container-data">
                    <p>
                        <span className={styles.statusOpen}>Ваш статус</span>
                        <span className={styles.name}>Ваше ИМЯ</span>
                        <span className={styles.code}>Ваш Код</span>
                    </p>
                </div>
            </div>
        );
    }
}

export default Codes;