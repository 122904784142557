import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import { HttpData, HttpNoData } from "../../../Core";
import styles from '../Style/SellProductStyle.module.css';
import { getCategories } from "../../../apiMethod/products";

function SellProduct() {

    const [currentDate, setCurrentDate] = useState(new Date());
    const [isHiddenCalendar, setIsHiddenCalendar] = useState(true);
    const [currentPlace, setCurrentPlace] = useState('');
    const [places, setPlaces] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const [productsSend, setProductsSends] = useState([]);
    const [jwt] = useState(localStorage.getItem('jwtToken'));
    const [productsSendShadow, setProductsSendShadow] = useState([]);

    const [isLoad, setIsLoad] = useState(true);

    const GetUpdateElements = () => {
        var result = [];

        for (let index = 0; index < productsSend.length; index++)
            for (let i = 0; i < productsSend[index].sends.length; i++)
                if (productsSend[index].sends[i].value != productsSendShadow[index].sends[i].value)
                    result.push(productsSend[index].sends[i]);

        return result;
    }

    const CategoriesSorted = (categories, data) => {
        var result = [];
        var shadow = [];

        for (let index = 0; index < categories.length; index++) {
            const currentElement = categories[index];
            result.push({
                id: currentElement.id,
                name: currentElement.name,
                sends: data.filter(o => o.categoryId === currentElement.id)
            });

            shadow.push({
                id: currentElement.id,
                name: currentElement.name,
                sends: data.filter(o => o.categoryId === currentElement.id).map(o => { return { ...o} })
            });
        }

        return { value: result, shadow: shadow };
    }

    async function DidMount() {

        const responsePlaces = await HttpNoData('/api/v0/productssend/places', 'GET', jwt);

        if (responsePlaces.statusSuccessful && !responsePlaces.isDataEmpty) {
            setPlaces(responsePlaces.data);
            setCurrentPlace(responsePlaces.data[0]);
            setIsLoad(false);
        }
        else console.log(responsePlaces.error);

        const responseCategories = await getCategories(jwt);

        if (responseCategories){

            const medals = responseCategories.filter(o => o.id == 1 || o.id == 6 || o.id == 7 || o.id == 8);
            const more = responseCategories.filter(o => o.id != 1 && o.id != 6 && o.id != 7 && o.id != 8).sort((a,b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
              
                // names must be equal
                return 0;
              });

            const categoriesData = [...medals, ...more];
            setProductCategories(categoriesData);

            const response = await HttpNoData('/api/v0/productssend?date=' + currentDate.toLocaleDateString().split('.').reverse().join('-') + '&placeId=' + responsePlaces.data[0].id, 'GET', jwt);


            if (response.statusSuccessful && !response.isDataEmpty){
                var sorted = CategoriesSorted(categoriesData, response.data);
                setProductsSends(sorted.value);
                setProductsSendShadow(sorted.shadow);
            }
            else console.log(response.error);
        }
    }

    useEffect(() => {
        DidMount();
    }, []);

    const ClickSave = async () => {

        var updates =  GetUpdateElements();

        if(updates.length <= 0)
            {
                setIsLoad(false);
                return;
            }

        var responseUpdate = await HttpData('/api/v0/productsSend', 'POST', jwt, updates);

        if (responseUpdate.statusSuccessful) {
            await DidMount();
            setIsLoad(false);
        } else console.log(responseUpdate.error);
    }

    const UpdateCurrentDate = async (newDate) => {

        if (isLoad)
            return;

        var responseDateData = await HttpNoData('/api/v0/productssend?date=' + newDate.toLocaleDateString().split('.').reverse().join('-') + '&placeId=' + currentPlace.id, 'GET', jwt);

        if (responseDateData.statusSuccessful && !responseDateData.isDataEmpty) {
            var sorted = CategoriesSorted(productCategories,responseDateData.data);
            setProductsSends(sorted.value);
            setProductsSendShadow(sorted.shadow);
            setCurrentDate(newDate);
        } else console.log(responseDateData.error);
    }

    const UpdateCurrentPlace = async (newPlace) => {

        var responsePlaceData = await HttpNoData('/api/v0/productssend?date=' + currentDate.toLocaleDateString().split('.').reverse().join('-') + '&placeId=' + newPlace.id, 'GET', jwt);

        if (responsePlaceData.statusSuccessful && !responsePlaceData.isDataEmpty) {
            var sorted = CategoriesSorted(productCategories, responsePlaceData.data);
            setProductsSends(sorted.value);
            setProductsSendShadow(sorted.shadow);
            setCurrentPlace(newPlace);
        } else console.log(responsePlaceData.error);
    }

    const UpdateValue = async (index, categoryIndex, newValue) => {
        // 1. Make a shallow copy of the items
        let categories = [...productsSend];
        // 2. Make a shallow copy of the item you want to mutate
        let sends = [...categories[categoryIndex].sends];

        let item = { ...sends[index] };
        // 3. Replace the property you're intested in
        item.value = newValue;
        // 4. Put it back into our array. N.B. we *are* mutating the array here
        //    but that's why we made a copy first
        sends[index] = item;
        // 5. Set the state to our new copy
        categories[categoryIndex].sends = sends;

        setProductsSends(categories);
    }

    const placesUI = places.map(item =>
        <button key={item.id} className={item.id == currentPlace.id ? styles.currentPlaceButton : styles.placeButton} onClick={() => { UpdateCurrentPlace(item); }}>
            {item.name}
        </button>);

    const productsSendUI = productsSend.map((category, categoryIndex) => <div key={category.id} className={styles.categoryItem}>
        <div className={styles.titleCategory}>{category.name}</div>
        <div className={styles.categorySends}>
            {
                category.sends.map((item, index) =>
                    <div key={index} className={styles.item}>
                        <div className={styles.item_text}>{item.productName}</div>
                        <input type="number" className={styles.item_input} value={item.value} onChange={(e) => { UpdateValue(index, categoryIndex, e.target.value); }} />
                    </div>)
            }
        </div>
    </div>);

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.content}>
                    <button onClick={() => { setIsHiddenCalendar(!isHiddenCalendar); }} className={styles.date}>
                        {currentDate.toLocaleDateString()}
                    </button>
                    <div className={styles.panelButtons}>
                        {placesUI}
                    </div>
                    <div className={styles.productsSend}>
                        {productsSendUI}
                    </div>
                    <button disabled={isLoad} className={styles.save_button} onClick={() => {
                        setIsLoad(true);
                        ClickSave();
                    }}>сохранить</button>
                </div>
                <div onClick={(e) => { if (e.target.className.includes('calendarBody')) setIsHiddenCalendar(true); }} className={styles.calendarBody} hidden={isHiddenCalendar}>
                    <Calendar onChange={(date) => UpdateCurrentDate(date)} value={currentDate} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default SellProduct;