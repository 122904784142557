import { HttpNoData } from "../Core";

export async function sumOnProducts(monthDate, ids, jwt){

    if(!ids || ids.length <= 0)
        return null;

    const response = await HttpNoData(`/api/v0/analyze/products/sum/${monthDate}?` + ids.map(o => "ids=" + o).join('&'), 'GET', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}

export async function sumOnExtraWorks(monthDate, ids, jwt){

    if(!ids || ids.length <= 0)
        return null;

    const response = await HttpNoData(`/api/v0/analyze/extraWorks/sum/${monthDate}?` + ids.map(o => "ids=" + o).join('&'), 'GET', jwt);

    return response.data;
}

export async function ProductOnDate(timeSpan, date, jwt){

    const response = await HttpNoData('/api/v0/analyze/products/' + timeSpan + (date ? '?date=' + date : ''), 'GET', jwt);
    
    if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}

export async function getDataDay(dateDay, jwt){

    const response = await HttpNoData('/api/v0/analyze/leads/' + dateDay, 'GET', jwt);
    
    if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}