import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { DownloadFile, HttpNoData } from '../../Core.js';
import styles from './ExcelExport.module.css';

function ExcelExport(props) {

    const [jwt, setJwt] = useState(props.jwt);
    const [isAnalytic, setIsAnalytic] = useState(false);
    const [isCoefficient, setIsCoefficient] = useState(false);
    const [isSends, setIsSends] = useState(false);
    const [isDefect, setIsDefect] = useState(false);
    const [isStable, setIsStable] = useState(false);
    const [isLeads, setIsLeads] = useState(false);
    const [isMachineMath, setIsMachineMath] = useState(false);

    const [isLastMonth, setIsLastMonth] = useState(false);
    const [date, setDate] = useState(new Date().toLocaleDateString().split('.').reverse().join('-'));
    const [workers, setWorkers] = useState([]);
    const [workerCategories, setWorkerCategories] = useState([]);
    const [isDownload, setIsDownload] = useState(false);

    useEffect(() => {

        async function DidMount() {
            const response = await HttpNoData('/api/v0/workers', 'GET', jwt);

            if (response.statusSuccessful) {
                const workersTemp = response.data;

                for (let index = 0; index < workersTemp.length; index++)
                    workersTemp[index].isCheck = false;

                setWorkers(workersTemp);
                setIsDownload(true);
            }

            const responseCategories = await HttpNoData('/api/v0/workerCategories', 'GET', jwt);

            if(responseCategories.statusSuccessful)
            setWorkerCategories(responseCategories.data.map(o => {
                o.isCheck = false;
                return o;
            }));
        }

        DidMount();
    }, []);

    const Download = async () => {

        const response = await DownloadFile('/api/v1/excel', 'POST', jwt, {
            isAnalytic: isAnalytic,
            isCoefficient: isCoefficient,
            isSends: isSends,
            isDefect: isDefect,
            isStable: isStable,
            isLeads: isLeads,
            isMachineMath: isMachineMath,
            isLastMonth: !isLastMonth,
            date: date,
            workers: workers.filter(item => item.isCheck).map(item => item.id)
        }, ((new Date().toJSON().replaceAll(':', '_').replaceAll('-', '_').replaceAll('.', '_'))) + '.xlsx');

        if (!response.statusSuccessful)
            console.log(response.error);
        else setIsDownload(true);
    }

    const CheckBox = (props) => {

        const { title, value, change } = props;

        return <div className={styles.checkItem}>
            <input className={styles.checkBody} checked={value} onChange={(e) => change(e.target.checked)} type="checkbox" />
            <div className={styles.checkTitle} onClick={() => change(!value)}>{title}</div>
        </div>
    }

    const ContentItem = (props) => {

        const { title, content } = props;

        return <div className={styles.contentItem}>
            <h4>{title}</h4>
            <div className={styles.contentItemBody}>
                {content}
            </div>
        </div>
    }

    const UpdateWorker = (id, value) => {

        const index = workers.findIndex(item => item.id === id);

        const updateList = workers;
        const updateItem = updateList[index];
        updateItem.isCheck = value;
        updateList[index] = updateItem;

        setWorkers([...updateList]);
    }

    const UpdateCategory = (id, value) => {

        const copyW = [...workers];

        for (let index = 0; index < copyW.length; index++) {
            if(copyW[index].workerCategoryId == id)
                copyW[index].isCheck = value;
        }

        setWorkers(copyW);
    }

    const CategoryView = ({ category }) => {

        const[currentWorkers] = useState(workers.filter(w => w.workerCategoryId == category.id));
        const[countCheck] = useState(workers.filter(w => w.workerCategoryId == category.id)
            .filter(w => w.isCheck).length);
        const[isShowWorkers, setIsShowWorkers] = useState(false);

        return <ContentItem key={category.id} title={<CheckBox key={category.id} title={category.name} value={
            countCheck > 0
        } change={(value) => UpdateCategory(category.id, value)}/>}
            content={<div>
                <button className={styles.buttonShow} onClick={() => setIsShowWorkers(!isShowWorkers)}>{isShowWorkers ? "Скрыть" : "Показать"} {countCheck}/{currentWorkers.length}</button>
                {
                isShowWorkers ?
                currentWorkers.map(item => <CheckBox key={item.id} title={item.name} value={item.isCheck} change={(value) => UpdateWorker(item.id, value)} />)
                : null
                }</div>}/>;
    }

    const PullViewButton = () => {
        const { ref, inView, entry } = useInView({
            threshold: 0,
            delay: 100,
        });

        return <div ref={ref} className={styles.block}>
            <button disabled={!isDownload} className={inView ? styles.download : styles.downloadFixed} onClick={() => {
            setIsDownload(false);
            Download();
        }}>{isDownload ? 'Скачать' : 'Подождите'}</button>
        </div>;
    }

    const stateCheckAll = isAnalytic || isCoefficient || isSends || isDefect || isStable || isLeads || isMachineMath || isLastMonth || workers.filter(o => o.isCheck).length > 0;

    return <div className={styles.psevdoBody}>
        <div className={styles.content}>
            <h1>Excel</h1>
            <div className={styles.contentItems}>
                <CheckBox title={'Выбрать все'} value={stateCheckAll} change={() => {
                    setIsAnalytic(!stateCheckAll);
                    setIsCoefficient(!stateCheckAll);
                    setIsSends(!stateCheckAll);
                    setIsDefect(!stateCheckAll);
                    setIsStable(!stateCheckAll);
                    setIsLeads(!stateCheckAll);
                    setIsMachineMath(!stateCheckAll);
                    setIsLastMonth(!stateCheckAll);
                    setWorkers([...workers.map(o =>{
                        o.isCheck = !stateCheckAll;
                        return o;
                    })]);
                }} />
                <ContentItem title={'Общее'} content={
                    <React.Fragment>
                        <CheckBox title={'Статистика'} value={isAnalytic} change={(value) => setIsAnalytic(value)} />
                        <CheckBox title={'Коэффициенты'} value={isCoefficient} change={(value) => setIsCoefficient(value)} />
                        <CheckBox title={'Отправленная продукция'} value={isSends} change={(value) => setIsSends(value)} />
                        <CheckBox title={'Дефекты'} value={isDefect} change={(value) => setIsDefect(value)} />
                        <CheckBox title={'Рентабельность '} value={isStable} change={(value) => setIsStable(value)} />
                        <CheckBox title={'Лиды'} value={isLeads} change={(value) => setIsLeads(value)} />
                        <CheckBox title={'Учитывать Лазерные ст.'} value={isMachineMath} change={(value) => setIsMachineMath(value)} />
                        <CheckBox title={'За все время'} value={isLastMonth} change={(value) => setIsLastMonth(value)} />
                        {
                            !isLastMonth ?
                                <input value={date} onChange={(e) => setDate(e.target.value)} type="date" />
                                : null
                        }
                    </React.Fragment>
                } />
                {
                    workerCategories.map(o => <CategoryView key={o.id} category={o}/>)
                }

            </div>
                <PullViewButton/>
        </div>
    </div>
}

export default ExcelExport;