import { HttpData, HttpNoData } from '../Core.js'
import '../components/Administrator/Notifications/Utils/utils'
export async function getNotifications(jwt) {
	const response = await HttpNoData('/api/v1/Notification', 'GET', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getWorkers(jwt) {
	const response = await HttpNoData('/api/v1/Workers', 'GET', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	console.log(response)
	return response.data
}

export async function postNotification(jwt, body) {
	const response = await HttpData('/api/v1/Notification', 'POST', jwt, body)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function putNotification(jwt, id, body) {
	const response = await HttpData(
		`/api/v1/Notification/${id}`,
		'PUT',
		jwt,
		body
	)
	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getNotificationTypes(jwt) {
	const response = await HttpNoData('/api/v1/Notification/type', 'GET', jwt)
	if (!response.statusSuccessful) return console.log(response.error)

	return response.data
}

export async function getWorkCategories(jwt) {
	const response = await HttpNoData('/api/v0/ExtraWorkCategories', 'GET', jwt)
	if (!response.statusSuccessful) return console.log(response.error)

	return response.data
}

export async function deleteNotification(jwt, id) {
	const response = await HttpNoData(`/api/v1/Notification/${id}`, 'DELETE', jwt)
	if (!response.statusSuccessful) return console.log(response.error)
}
