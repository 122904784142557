import React, { useEffect, useState } from "react";
import { sumOnExtraWorks, sumOnProducts } from "../../../apiMethod/analyze";
import styles from '../Styles/FormulasMath.module.css';
import { Alert, OverlayTrigger, Tooltip } from "react-bootstrap";

function FormulasMath({ jwt }) {

    const [productsRationCurrent, setProductsRationCurrent] = useState();
    const [productsRationPrev, setProductsRationPrev] = useState();

    const [currentMonth] = useState(new Date());
    const [prevMonth] = useState(new Date(new Date() - new Date().getDate() * 24 * 60 * 60 * 1000));

    const GetRationDate = async (date) => {

        const defaultMathPromise = async (promiseOne, promiseTwo) => {
            const sumOne = await promiseOne;

            if (!sumOne || sumOne <= 0)
                return 0;

            const sumTwo = await promiseTwo;

            if (!sumTwo || sumTwo <= 0)
                return 0;

            return sumOne / sumTwo;
        }

        const OA_A = async () => {
            const sumProducts = await sumOnProducts(date, [1], jwt);

            if (!sumProducts || sumProducts <= 0)
                return 0;

            const sumExtraWorks = await sumOnExtraWorks(date, [16], jwt);

            if (!sumExtraWorks || sumExtraWorks <= 0)
                return 0;

            return sumProducts / (sumExtraWorks / 2);
        };

        const OM_M = async () => {
            const sumProducts = (await sumOnProducts(date, [2, 24], jwt) + (await sumOnProducts(date, [19], jwt) * 3) + (await sumOnProducts(date, [21], jwt) * 2));

            if (!sumProducts || sumProducts <= 0)
                return 0;

            const sumExtraWorks = await sumOnExtraWorks(date, [17, 19], jwt);

            if (!sumExtraWorks || sumExtraWorks <= 0)
                return 0;

            return sumProducts / sumExtraWorks;
        }

        return [{
            name: 'OA/A',
            formula: 'ак. медали с гравировкой / (Сборка медалей (кол-во в накладках) / 2)',
            value: await OA_A()
        }, {
            name: 'ОУА/УА',
            formula: 'ак. медали с уф печатью / УФ печать медалей',
            value: await defaultMathPromise(sumOnProducts(date, [14], jwt), sumOnExtraWorks(date, [7], jwt))
        }, {
            name: 'ОМ/М',
            formula: '(метал. медали + метал. медали резные + метал. медали механические * 3 + метал. медали многослойные * 2) / (Покраска порошком - медали + Покраска акриловой - медали)',
            value: await OM_M()
        }, {
            name: 'О/ОО',
            formula: '(ордена + штампованные медали + объемные медали) / (Покраска порошком Литье + Катафорез медалей)',
            value: await defaultMathPromise(sumOnProducts(date, [23, 4, 3], jwt), sumOnExtraWorks(date, [32, 49], jwt))
        }, {
            name: 'ОД/Д',
            formula: 'диз. ленты / (Сублимация репса (до 200шт.) + Сублимация атласная лента (до 200шт.) + Сублимация репса (больше 200шт.) + Сублимация атласная лента (больше 200шт.))',
            value: await defaultMathPromise(sumOnProducts(date, [18], jwt), sumOnExtraWorks(date, [11, 12, 96, 97], jwt))
        }, {
            name: 'ОУС/УС',
            formula: 'ак. статуэтки с уф печатью / УФ печать кубков',
            value: await defaultMathPromise(sumOnProducts(date, [16], jwt), sumOnExtraWorks(date, [8], jwt))
        }, {
            name: 'ОМС/МС',
            formula: 'метал. статуэтки / (Покраска порошком - кубки (разогнутые) + Покраска порошком - кубки (согнутые))',
            value: await defaultMathPromise(sumOnProducts(date, [17], jwt), sumOnExtraWorks(date, [86, 87], jwt))
        }, {
            name: 'ОЛ/Л',
            formula: '(метал. медали резные + ак. медали многослойные + метал. медали многослойные + метал. медали механические + ак. медали резные + ак. медали с уф печатью + штампованные медали + объемные медали + метал. медали + ак. медали с гравировкой) / (Сшивка медалей  (15 мм.) + Сшивка медалей  (25 мм.) + Сшивка лент на медалях + Сшивка лент на карабинах + Аутсорс (Сшивка лент))',
            value: await defaultMathPromise(sumOnProducts(date, [24, 22, 21, 19, 15, 14, 4, 2, 1, 3], jwt), sumOnExtraWorks(date, [94, 95, 42, 43, 76], jwt))
        }, {
            name: 'ОЗ/З',
            formula: '(метал. брелки + метал. значки + ак. значки + ак. брелки) / УФ печать значков/брелоков',
            value: await defaultMathPromise(sumOnProducts(date, [9, 7, 6, 10], jwt), sumOnExtraWorks(date, [9], jwt))
        }, {
            name: 'НП/ГН',
            formula: '(Гравировка накладок (до 10 сек) + Гравировка накладок (до 25 сек) + Гравировка до 3 сек + Гравировка накладок (до 5 сек)) / Чистка пластика',
            value: await defaultMathPromise(sumOnExtraWorks(date, [14, 15, 31, 65], jwt), sumOnExtraWorks(date, [2], jwt))
        }, {
            name: 'АИ/НС',
            formula: '(ак. медали с гравировкой + ак. значки + ак. брелки + магниты + ак. медали с уф печатью + ак. медали резные + ак. медали многослойные) / Чистка стекла',
            value: await defaultMathPromise(sumOnProducts(date, [1, 10, 6, 12, 14, 15, 22], jwt), sumOnExtraWorks(date, [3], jwt))
        }];
    }

    useEffect(() => {

        async function didMount() {

            const current = await GetRationDate(currentMonth.toLocaleDateString().split('.').reverse().join('-'));
            const prev = await GetRationDate(prevMonth.toLocaleDateString().split('.').reverse().join('-'));

            setProductsRationCurrent(current);
            setProductsRationPrev(prev);
        }

        didMount();
    }, []);

    return <div className={styles.main}>
        <div className={styles.content}>
            <div className={styles.headContent}>
                <label className={styles.titleChart}>Рентабельность</label>
            </div>

            <div className={styles.monthBlock}>
                <div className={styles.monthText}>{currentMonth.toLocaleDateString('default', { month: "long" }).replace(/./, c => c.toUpperCase())}</div>
                <div className={styles.stateMonth}>текущий месяц</div>
                <div className={styles.elements}>
                    {
                        productsRationCurrent && productsRationCurrent.length > 0 ?
                            productsRationCurrent.map((item, index) => {

                                const value = +(item.value * 100).toFixed(2);

                                return <div key={index} className={styles.productRation}>
                                    <div className={styles.productRationName}>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={(props) => <Tooltip {...props}>
                                                {item.formula}
                                            </Tooltip>}>
                                            <div>{item.name}</div>
                                        </OverlayTrigger>
                                    </div>
                                    <svg
                                        width="100" height="100" viewBox="0 0 100 100"
                                        className={styles.circular_progress} style={{ '--progress': value }}
                                    >
                                        <circle className={styles.bg + ' ' + styles.circle} />
                                        <circle className={styles.fg + ' ' + styles.circle} />
                                    </svg>
                                    <div style={{ position: 'relative', top: '-60px' }}>{value}%</div>
                                </div>
                            })
                            : <Alert variant="danger" dismissible>
                                <Alert.Heading>Ох, щелк! У вас ошибка!</Alert.Heading>
                                <p>
                                    Что-то пошло не по плану, попробуйте перезагрузить страницу! Если не помогло тогда сообщите разработчику и дождитесь обновления.
                                </p>
                            </Alert>
                    }
                </div>
            </div>

            <div className={styles.monthBlock}>
                <div className={styles.monthText}>{prevMonth.toLocaleDateString('default', { month: "long" }).replace(/./, c => c.toUpperCase())}</div>
                <div className={styles.stateMonth}>прошлый месяц</div>
                <div className={styles.elements}>
                    {
                        productsRationPrev && productsRationPrev.length > 0 ?
                            productsRationPrev.map((item, index) => {

                                const value = +(item.value * 100).toFixed(2);

                                return <div key={index} className={styles.productRation}>
                                    <div className={styles.productRationName}>
                                    <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={(props) => <Tooltip {...props}>
                                                {item.formula}
                                            </Tooltip>}>
                                            <div>{item.name}</div>
                                        </OverlayTrigger>
                                    </div>
                                    <svg
                                        width="100" height="100" viewBox="0 0 100 100"
                                        className={styles.circular_progress} style={{ '--progress': value }}
                                    >
                                        <circle className={styles.bg + ' ' + styles.circle} />
                                        <circle className={styles.fg + ' ' + styles.circle} />
                                    </svg>
                                    <div style={{ position: 'relative', top: '-60px' }}>{value}%</div>
                                </div>
                            })
                            : <Alert variant="danger" dismissible>
                                <Alert.Heading>Ох, щелк! У вас ошибка!</Alert.Heading>
                                <p>
                                    Что-то пошло не по плану, попробуйте перезагрузить страницу! Если не помогло тогда сообщите разработчику и дождитесь обновления.
                                </p>
                            </Alert>
                    }
                </div>
            </div>
        </div>
    </div>;
}

export default FormulasMath;