import { Button, Modal } from 'react-bootstrap'

const EditDeleteView = ({ show, setShow, deleteNotification }) => {
	return (
		<Modal show={show} onHide={() => setShow(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Подтвердите действие</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Вы уверены что хотите <strong>Удалить</strong> уведомление?
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={() => setShow(false)}>
					Закрыть
				</Button>
				<Button
					variant='primary'
					onClick={() => {
						deleteNotification()
						setShow(false)
						
					}}
				>
					Сохранить
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default EditDeleteView
